import { getPlayers } from "api/greedy";
import Lucky77Settings from "components/models/Lucky77Settings";
import React, { useEffect } from "react";
import { useState } from "react";
import io from "socket.io-client";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { changeWinRate } from "store/slice/lucky77";
import InfiniteScroll from "react-infinite-scroll-component";
import Sort from "components/Sort/Sort";
import { numberFormatter } from "utils/number";
import Lucky77History from "components/models/Lucky77History";
import Percentage from "components/Settings/Percentage";

import getJwtFromLocalStorage from "utils/localStorageUtils";

function Lucky77() {
  const [isLoading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [users, setUsers] = useState([]);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [adminJwt, setAdminJwt] = useState(null);
  const [socket, setSocket] = useState(null);

  const [orders, setOrders] = useState([]);

  const loadPlayers = async () => {
    try {
      const { data } = await getPlayers(users.length, 20, orders);

      setHasMore(data?.users?.length === 20);
      setUsers([...users, ...data?.users]);

      setLoading(false);
    } catch (error) {
      NotificationManager.error(
        error?.response?.data?.error?.message || error.message
      );
    }
  };

  useEffect(() => {
    loadPlayers();
  }, []);

  const handleOrder = (order) => {
    if (orders.find((o) => o.key === order.key && o.value === order.value)) {
      setOrders((prev) => prev.filter((p) => p.key !== order.key));
    } else if (
      orders.find((o) => o.key === order.key && o.value !== order.value)
    ) {
      setOrders((prev) =>
        prev.map((p) => {
          p.value = order.value;

          return p;
        })
      );
    } else {
      setOrders((prev) => [...prev, order]);
    }
  };

  const loadFilteredData = async (orders) => {
    try {
      const { data } = await getPlayers(0, 20, orders);

      setHasMore(data?.users?.length === 20);
      setUsers(data?.users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadFilteredData(orders);
  }, [orders]);

  const [time, setTime] = useState(0);

  const [board_a_coin, setBoardACoin] = useState(0);
  const [board_b_coin, setBoardBCoin] = useState(0);
  const [board_c_coin, setBoardCCoin] = useState(0);
  const [board_d_coin, setBoardDCoin] = useState(0);
  const [board_e_coin, setBoardECoin] = useState(0);
  const [board_f_coin, setBoardFCoin] = useState(0);
  const [board_g_coin, setBoardGCoin] = useState(0);
  const [board_h_coin, setBoardHCoin] = useState(0);

  useEffect(() => {
    const result = getJwtFromLocalStorage("HL_GAME_AUTH_USER_DATA");
    setAdminJwt(result);
  }, []);

  useEffect(() => {
    if (adminJwt) {
      let socket = io(`${process.env.REACT_APP_SOCKET_BASE_URL}/ferris-wheel`, {
        auth: {
          UID: adminJwt,
        },
      });

      socket.on("connect", () => {
        console.log("socket connected: ", socket.connected);
        setSocket(socket);
      });

      socket.on("connect_error", (error) => {
        console.error("Connection error:", error);
        setSocket(null);
      });

      socket.on("disconnect", (reason) => {
        console.log("Disconnected:", reason);
        setSocket(null);
      });

      socket.on("GREEDY_BET_PLACED", (board, coin) => {
        switch (board) {
          case "A":
            setBoardACoin((prev) => prev + coin);
            break;
          case "B":
            setBoardBCoin((prev) => prev + coin);
            break;
          case "C":
            setBoardCCoin((prev) => prev + coin);
            break;
          case "D":
            setBoardDCoin((prev) => prev + coin);
            break;
          case "E":
            setBoardECoin((prev) => prev + coin);
            break;
          case "F":
            setBoardFCoin((prev) => prev + coin);
            break;
          case "G":
            setBoardGCoin((prev) => prev + coin);
            break;
          case "H":
            setBoardHCoin((prev) => prev + coin);
            break;

          default:
            break;
        }
      });

      socket.on("TIK", (time) => setTime(time));

      return () => {
        socket.off("GREEDY_BET_PLACED");
        socket.off("TIK");
      };
    }
  }, [adminJwt]);

  useEffect(() => {
    if (time === 1)
      setTimeout(() => {
        setBoardACoin(0);
        setBoardBCoin(0);
        setBoardCCoin(0);
        setBoardDCoin(0);
        setBoardECoin(0);
        setBoardFCoin(0);
        setBoardGCoin(0);
        setBoardHCoin(0);
      }, 10000);
  }, [time]);

  const triggerWinBoard = (board) => {
    if (!Boolean(time) || !socket) return;

    socket?.emit("GREEDY_TRIGGER_WIN", board);
    NotificationManager.success("Command successfully sent.");
  };

  return (
    <>
      {showSettingsModal && (
        <Lucky77Settings show onHide={() => setShowSettingsModal(false)} />
      )}

      {showHistoryModal && (
        <Lucky77History show onHide={() => setShowHistoryModal(false)} />
      )}

      {isLoading ? (
        <Container>
          <Row>
            <Col xs={12} className="py-4 text-center">
              <Spinner animation="grow" />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid>
          <Row>
            <Percentage name="greedy" />

            <Col xs={12} className="mt-4">
              <p className="live">
                Live beans. Game end in: {Boolean(time) ? time : "--"} second.
              </p>
            </Col>

            <Col xs={12} className="mb-3">
              <Table className=" table-striped">
                <thead>
                  <tr>
                    <th colSpan={4}>
                      <div className="d-flex justify-content-between">
                        <h4>Salad</h4>

                        <div>
                          <Button
                            size="sm"
                            onClick={() => {
                              if (confirm("Are you sure?")) {
                                triggerWinBoard("BCDE");
                              }
                            }}
                          >
                            Trigger Salad
                          </Button>
                        </div>
                      </div>
                    </th>
                  </tr>

                  <tr>
                    <th>5x</th>
                    <th>5x</th>
                    <th>5x</th>
                    <th>5x</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <Button
                        size="sm"
                        style={{
                          minWidth: 100,
                          borderColor: board_b_coin ? "" : "#838385",
                        }}
                        onClick={() => triggerWinBoard("B")}
                      >
                        {numberFormatter(board_b_coin)}
                      </Button>
                    </td>
                    <td>
                      <Button
                        size="sm"
                        style={{
                          minWidth: 100,
                          borderColor: board_c_coin ? "" : "#838385",
                        }}
                        onClick={() => triggerWinBoard("C")}
                      >
                        {numberFormatter(board_c_coin)}
                      </Button>
                    </td>
                    <td>
                      <Button
                        size="sm"
                        style={{
                          minWidth: 100,
                          borderColor: board_d_coin ? "" : "#838385",
                        }}
                        onClick={() => triggerWinBoard("D")}
                      >
                        {numberFormatter(board_d_coin)}
                      </Button>
                    </td>
                    <td>
                      <Button
                        size="sm"
                        style={{
                          minWidth: 100,
                          borderColor: board_e_coin ? "" : "#838385",
                        }}
                        onClick={() => triggerWinBoard("E")}
                      >
                        {numberFormatter(board_e_coin)}
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>

            <Col xs={12} className="mb-3">
              <Table className=" table-striped">
                <thead>
                  <tr>
                    <th colSpan={4}>
                      <div className="d-flex justify-content-between">
                        <h4>Pizza</h4>

                        <div>
                          <Button
                            size="sm"
                            onClick={() => {
                              if (confirm("Are you sure?")) {
                                triggerWinBoard("FGHA");
                              }
                            }}
                          >
                            Trigger Pizza
                          </Button>
                        </div>
                      </div>
                    </th>
                  </tr>

                  <tr>
                    <th>10x</th>
                    <th>15x</th>
                    <th>25x</th>
                    <th>45x</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <Button
                        size="sm"
                        style={{
                          minWidth: 100,
                          borderColor: board_h_coin ? "" : "#838385",
                        }}
                        onClick={() => triggerWinBoard("H")}
                      >
                        {numberFormatter(board_h_coin)}
                      </Button>
                    </td>
                    <td>
                      <Button
                        size="sm"
                        style={{
                          minWidth: 100,
                          borderColor: board_g_coin ? "" : "#838385",
                        }}
                        onClick={() => triggerWinBoard("G")}
                      >
                        {numberFormatter(board_g_coin)}
                      </Button>
                    </td>
                    <td>
                      <Button
                        size="sm"
                        style={{
                          minWidth: 100,
                          borderColor: board_f_coin ? "" : "#838385",
                        }}
                        onClick={() => triggerWinBoard("F")}
                      >
                        {numberFormatter(board_f_coin)}
                      </Button>
                    </td>
                    <td>
                      <Button
                        size="sm"
                        style={{
                          minWidth: 100,
                          borderColor: board_a_coin ? "" : "#838385",
                        }}
                        onClick={() => triggerWinBoard("A")}
                      >
                        {numberFormatter(board_a_coin)}
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>

            <Col xs={12}>
              <small className="text-muted">
                Note: Click on a button to mark it as a winning board. This
                action will be applied for only one round. also, you can change
                your decision anytime by clicking another button..
              </small>
            </Col>

            <Col xs={12} className="py-4">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Greedy Players</Card.Title>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <InfiniteScroll
                    dataLength={users.length} //This is important field to render the next data
                    next={loadPlayers}
                    hasMore={hasMore}
                    loader={
                      <div className="text-center py-4">
                        <Spinner animation="grow" />
                      </div>
                    }
                    scrollableTarget="main-panel"
                  >
                    <Table className="table-hover table-striped">
                      <thead>
                        <tr>
                          <th className="border-0">User ID</th>
                          <th className="border-0">Name</th>
                          <th className="border-0">
                            <div className="text-center">
                              Total Played <br /> Games{" "}
                              <Sort
                                orders={orders}
                                name="totalPlayed"
                                handleOrder={handleOrder}
                              />
                            </div>
                          </th>
                          <th className="border-0">
                            <div className="text-center">
                              Total Games <br /> Win
                              <Sort
                                orders={orders}
                                name="totalWin"
                                handleOrder={handleOrder}
                              />
                            </div>
                          </th>
                          <th className="border-0">
                            <div className="text-center">
                              Win Rate <br />
                              <Sort
                                orders={orders}
                                name="winRate"
                                handleOrder={handleOrder}
                              />
                            </div>
                          </th>
                          <th className="border-0">
                            <div className="text-center">
                              Total used <br /> beans
                              <Sort
                                orders={orders}
                                name="totalSpend"
                                handleOrder={handleOrder}
                              />
                            </div>
                          </th>
                          <th className="border-0">
                            <div className="text-center">
                              Total earned <br /> beans
                              <Sort
                                orders={orders}
                                name="totalEarned"
                                handleOrder={handleOrder}
                              />
                            </div>
                          </th>
                          <th className="border-0">
                            <div className="text-center">
                              Available Beans <br />
                              <Sort
                                orders={orders}
                                name="beans"
                                handleOrder={handleOrder}
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((u, i) => (
                          <tr key={i}>
                            <td>{u?.userId}</td>
                            <td className=" max-w-100-p">
                              {u?.nickname || "N/A"}

                              {Boolean(u?.playing) && (
                                <span
                                  style={{
                                    width: 10,
                                    height: 10,
                                    background: "green",
                                    borderRadius: 5,
                                    display: "inline-block",
                                    marginLeft: 8,
                                  }}
                                  title="Playing"
                                ></span>
                              )}
                            </td>
                            <td className="text-center">{u.totalPlayed}</td>
                            <td className="text-center">{u.totalWin}</td>
                            <td className="text-center">
                              {parseInt(u.winRate * 100)}%
                            </td>
                            <td className="text-center">
                              {numberFormatter(u.totalSpend)}
                            </td>
                            <td className="text-center">
                              {numberFormatter(u.totalEarned)}
                            </td>
                            <td className="text-center">
                              {numberFormatter(u.beans || 0)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </InfiniteScroll>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default Lucky77;
