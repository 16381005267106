function getJwtFromLocalStorage(key) {
  const userData = localStorage.getItem(key);

  if (!userData) return null; // Return null if there's no user data

  try {
    const parsedData = JSON.parse(userData); // Parse the JSON string into an object
    return parsedData.jwt || null; // Return the jwt key, or null if it doesn't exist
  } catch (error) {
    console.error("Error parsing user data from localStorage:", error);
    return null; // Return null if parsing fails
  }
}

export default getJwtFromLocalStorage;
