import axios from "axios";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import numeral from "numeral";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

function gameProfit() {
  const [selectedGame, setSelectedGame] = useState("FERRIS_WHEEL");

  const initialGameData = {
    companyProfit: 0,
    playersTotalEarned: 0,
    playersTotalSpend: 0,
  };

  const [gameData, setGameData] = useState(initialGameData);

  const beginningOfMonth = new Date();
  beginningOfMonth.setDate(1);

  const [fromDate, setFromDate] = useState(
    beginningOfMonth.toLocaleDateString("en-CA")
  );

  const [toDate, setToDate] = useState(new Date().toLocaleDateString("en-CA"));

  useEffect(() => {
    setGameData(initialGameData);
    getGameProfitData();
  }, [fromDate, toDate, selectedGame]);

  const getGameProfitData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/game-profit-history?game_name=${selectedGame}&date_from=${fromDate}&date_to=${toDate}`
    );
    setGameData(response?.data?.data);
  };

  const formatNumber = (num) => {
    return numeral(num).format("0,0");
  };

  return (
    <>
      <h2 className="text-center">Game specific profit</h2>
      <h3 className="text-center fw-bold p-3 mb-4 border border-3 border-info-subtle rounded">
        {selectedGame}
      </h3>

      <div className="d-flex flex-wrap justify-content-center justify-content-sm-around  ">
        <div className="mb-3">
          <p>Select Game</p>
          <Dropdown>
            <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
              {selectedGame}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setSelectedGame("FERRIS_WHEEL");
                }}
                href="#/action-1"
              >
                Ferris Wheel
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setSelectedGame("TEEN_PATTI");
                }}
                href="#/action-2"
              >
                Teen Patti
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="mb-5">
          <p>From</p>
          <Calendar
            onChange={setFromDate}
            value={fromDate}
            defaultValue={fromDate}
          />
        </div>

        <div>
          <p>To</p>
          <Calendar onChange={setToDate} value={toDate} defaultValue={toDate} />
        </div>
      </div>

      <div className="text-center mt-5">
        <p className="fs-2">
          Players total spend on game :{" "}
          <Badge bg="secondary">
            {formatNumber(gameData.playersTotalSpend)}
          </Badge>{" "}
        </p>
        <p className="fs-2">
          Players total earned from game :{" "}
          <Badge bg="secondary">
            {formatNumber(gameData.playersTotalEarned)}
          </Badge>
        </p>
        <h4 className="fs-1">
          Company profit :{" "}
          <Badge bg={gameData.companyProfit < 0 ? "danger" : "success"}>
            {formatNumber(gameData.companyProfit)}
          </Badge>
        </h4>
      </div>
    </>
  );
}

export default gameProfit;
